import './Footer.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import { faAirFreshener } from '@fortawesome/free-solid-svg-icons'
import { faCopyright } from '@fortawesome/free-regular-svg-icons'
import { faInstagram, faYoutube } from '@fortawesome/free-brands-svg-icons'

export default function Footer() {
    return (
        <div id="footer">
            <a href="https://www.instagram.com/solka_mylchuk/" target={'_blank'} rel="noreferrer"><FontAwesomeIcon className='faIcon insta' icon={faInstagram} size="3x" /></a>
            <a href="https://www.youtube.com/c/smhealthfitness7" target={'_blank'} rel="noreferrer"><FontAwesomeIcon className='faIcon youtube' icon={faYoutube} size="3x" /></a>
            {/* <FontAwesomeIcon icon={faAirFreshener} /> */}
            <br />
            <p className='copyright'>
            <FontAwesomeIcon icon={faCopyright} /> Copyright Solomiya Mylchuk
            </p>
        </div>
    )
}