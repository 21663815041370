import Navigation from '../Components/Menu/Navigation';
import { Outlet } from 'react-router-dom';
import Footer from '../Components/Footer/Footer'
import "./Layout.css";

function Layout(){
    return <div>
        <Navigation />
        <Outlet />
        <Footer />
    </div>
}

export default Layout;