import Banner from '../Components/Banner/Banner';
import FeaturedItems from '../Components/Featured/Featured'
// import Promotions from '../Components/Featured/Promotion'
// import Feedbacks from '../Components/Feedback/Feedback'
import "./MainPage.css";
import photoHabit from '../Images/build-a-habit-web.jpg'
import photoCorrectly from '../Images/do it correctly web.jpg'
import photoImproving from '../Images/keep improving web.jpg'

function MainPage(){
    return <div className='MainPage'>
        <Banner />
        <FeaturedItems featured={featured} />
        {/* <Promotions /> */}
        {/* <Feedbacks /> */}
    </div>
}

export default MainPage;

var featured = {
    items: [
      {
        content: {
          heading: "Build a habit",
          body: "Build a schedule with me and you'll see that it makes wonder for building your body's habit of doing workouts regularly",
        },
        image: photoHabit,
      },
      {
        content: {
          heading: "Do it correctly",
          body: "As a PT I not only help you plan the exercises, I also make sure that you do them correctly to ensure that you get the maximum benefit",
        },
        image: photoCorrectly,
      },
      {
        content: {
          heading: "Keep improving",
          body: "Are you already training on a habit but find that you've plateaued? Book time with me and I'll make sure you keep improving",
        },
        image: photoImproving,
      },
    ],
  };