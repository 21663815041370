import FeaturedItems from "../Components/Featured/Featured";
import aboutMeImage from "../Images/who i am web.jpg";
import educationImage from "../Images/education web.jpg";
import workoutImage from "../Images/DALL·E 2022-12-31 16.59.37 - person working out at home under guidance, bright, digital art (Small).png";

function AboutMePage() {
  return (
    <div>
      <FeaturedItems featured={info} />
    </div>
  );
}

export default AboutMePage;

var info = {
  items: [
    {
      content: {
        heading: "Who I am",
        paragraphs: [
            "I am a very motivated and eager woman who don't like to stay still. I always want to stay active and to improve myself or my clients.",
            "After a total of 7 years of university studies I have developed a very good understanding of the human body and what is good or bad for it.",
            "For each of my clients I made unique workouts each time by pouring in all of my collected knowledge, their goals and detailed knowledge of how their body works.",
            "That commitment to my clients gave me a very good reputation among politicians and entrepreneurs in Lviv.",
        ],
      },
      image: aboutMeImage,
    },
    {
      content: {
        heading: "Education",
        list: [
          "5 years of university education at XYZ",
          "1 year master xxx",
          "1 year master yyy",
          "Certification in AAA",
          "Certification in BBB",
          "Certification in CCC",
          "Certification in DDD",
          "6+ years of work experience",
        ],
      },
      image: educationImage,
    },
    {
      content: {
        heading: "Work experience",
        paragraphs: [
            "I began my career in Ukraine working at a health clinic specializing at massages. I had a lot of recurring clients and after some time I realized I wanted to do more to help them. They come back with the same problems after a year, and massages can only do so much for them.",
            "After that I started my own personal training company offering personalized training and massages at home. Every time I made a new set of exercises for each client based on their strenght and what they need to train to reach their goal. My clients, both men and women, were so happy with my efforts that the reputation they gave me brought more and more clients until I had my days full. Now I have many years of experience."
        ],
      },
      image: workoutImage,
    },
  ],
};
