import "./Navigation.css";
import { NavLink } from "react-router-dom";

export default function Navigation() {
  return (
    <nav className="nav">
      <div className="container">
        <div className="logo row">
          <div>
            <p className="logo__title">SM Health & Fitness</p>
            <p className="logo__subtitle">With Solomiya Mylchuk</p>
          </div>
        </div>
        <ul className="menu">
          <li className="nav__item" key="home">
            <NavLink className="nav__link" to={"/"}>Home</NavLink>
          </li>
          <li className="nav__item" key="about">
            <NavLink className="nav__link" to={"/about"}>About</NavLink>
          </li>
          <li className="nav__item" key="contact">
            <NavLink className="nav__link" to={"/contact"}>Contact</NavLink>
          </li>
        </ul>
      </div>
    </nav>
  );
}