import "./Featured.css";

export default function FeaturedItems(props) {
  return (
    <div className="featuredItems">
      {props.featured.items.map(function (item, i) {
        return <Featured key={i} number={i} item={item} />;
      })}
    </div>
  );
}

export function Featured(props) {
  return (
    <div className="feature">
        <div>
          <img src={props.item.image} alt="Something nice to match the text" />
        </div>
        <Description content={props.item.content} number={props.number} />
    </div>
  );
}

function Description(props) {
  return (
    <div className="content">
        <h2 className="feature__title">{props.content.heading}</h2>
        {props.content.list && (
          <ul>
            {props.content.list.map((listItem, index) => {
              return <li key={index}>{listItem}</li>;
            })}
          </ul>
        )}

        {props.content.paragraphs &&
          props.content.paragraphs.map((item, index) => {
            return <p key={index}>{item}</p>;
          })}
        {<p className="feature__text">{props.content.body}</p>}
    </div>
  );
}
