import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Layout from './pages/Layout';
import MainPage from './pages/MainPage';
import AboutMePage from "./pages/AboutMePage";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Layout />,
    errorElement: <div>ERROR! Page not found *insert unhappy face here*</div>,
    children: [
      {
        index: true,
        element: <MainPage />
      },
      {
        path: "about",
        element: <AboutMePage />
      }
    ],
  },
  {
    path: "about2",
    element: <div>About page 2!</div>,
    errorElement: <div>ERROR! Page not found *insert unhappy face here*</div>,
  },
]);

ReactDOM.render(
  <React.StrictMode>
    <RouterProvider router = {router} />
  </React.StrictMode>,
  // <React.StrictMode>
  //   <BrowserRouter>
  //     <App /> 
  //   </BrowserRouter>
  // </React.StrictMode>,
  //     <Navigation />
  //     <Banner />
  //     <FeaturedItems />
  //     <Promotions />
  //     <Feedbacks />
  //     <Footer />
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
