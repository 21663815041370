import './Banner.css'
import photo from '../../Images/Front web.jpg'

export default function Banner() {
    return (
        <div className="banner">
            <div className="scaling">
                <img src={photo} alt="Solomiya posing in front of a white wall" width="2000" height="1038" />
            </div>
        </div>
    )
}